import React from 'react'
import './verifying-prescription.css'
import { useNavigate } from 'react-router-dom'

const VerifyingPrescription = (props) => {
    const navigate=useNavigate()
  return (
    <div className="verifying-prescription-container">
   
      <div className="verifying-prescription-frame215">
        <div className="verifying-prescription-frame332">
          <div className="verifying-prescription-frame383">
            <div className="verifying-prescription-readingglassesbro">
              <div className="verifying-prescription-backgroundsimple"></div>
              <div className="verifying-prescription-mug">
                <img
                  src="/external/vector3188-mbh9.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector"
                />
                <img
                  src="/external/vector3188-l8wd.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector001"
                />
                <img
                  src="/external/vector3188-m9t4.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector002"
                />
                <img
                  src="/external/vector3188-4fo8.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector003"
                />
                <img
                  src="/external/vector3188-f5vph.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector004"
                />
                <img
                  src="/external/vector3188-bqf.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector005"
                />
                <img
                  src="/external/vector3188-qsv7.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector006"
                />
                <img
                  src="/external/vector3188-y5fn.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector007"
                />
              </div>
              <div className="verifying-prescription-books2">
                <img
                  src="/external/vector3188-whmu.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector008"
                />
                <img
                  src="/external/vector3188-ljdp.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector009"
                />
                <img
                  src="/external/vector3188-ewba.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector010"
                />
                <img
                  src="/external/vector3188-znhh.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector011"
                />
                <img
                  src="/external/vector3188-1xaj.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector012"
                />
                <img
                  src="/external/vector3188-wf3o.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector013"
                />
                <img
                  src="/external/vector3188-fzpt.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector014"
                />
                <img
                  src="/external/vector3188-5ktt.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector015"
                />
                <img
                  src="/external/vector3188-r8ca.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector016"
                />
                <img
                  src="/external/vector3188-l71.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector017"
                />
              </div>
              <div className="verifying-prescription-book1">
                <img
                  src="/external/vector3188-6ol8.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector018"
                />
                <img
                  src="/external/vector3188-3w3s.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector019"
                />
                <img
                  src="/external/vector3188-ehj8.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector020"
                />
                <img
                  src="/external/vector3188-iqsd.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector021"
                />
                <img
                  src="/external/vector3188-7l.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector022"
                />
                <img
                  src="/external/vector3188-zbub.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector023"
                />
                <img
                  src="/external/vector3188-q7zo.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector024"
                />
                <img
                  src="/external/vector3188-5vnj.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector025"
                />
                <img
                  src="/external/vector3188-lb4.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector026"
                />
                <img
                  src="/external/vector3188-6xfh.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector027"
                />
                <img
                  src="/external/vector3188-8tgw.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector028"
                />
                <img
                  src="/external/vector3188-9zz.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector029"
                />
                <img
                  src="/external/vector3188-pnc.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector030"
                />
                <img
                  src="/external/vector3188-0iwb.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector031"
                />
                <img
                  src="/external/vector3188-zt9t.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector032"
                />
                <img
                  src="/external/vector3188-9rjl.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector033"
                />
                <img
                  src="/external/vector3188-knnu.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector034"
                />
                <img
                  src="/external/vector3188-tqd.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector035"
                />
                <img
                  src="/external/vector3188-1tg6.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector036"
                />
                <img
                  src="/external/vector3188-r3z.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector037"
                />
                <img
                  src="/external/vector3188-85uw.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector038"
                />
                <img
                  src="/external/vector3188-i2ma.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector039"
                />
                <img
                  src="/external/vector3188-ca27.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector040"
                />
                <img
                  src="/external/vector3188-ldgr.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector041"
                />
                <img
                  src="/external/vector3188-n5tl.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector042"
                />
                <img
                  src="/external/vector3188-1oid.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector043"
                />
                <img
                  src="/external/vector3188-6y5t.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector044"
                />
                <img
                  src="/external/vector3188-w5l.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector045"
                />
                <img
                  src="/external/vector3188-oug.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector046"
                />
                <img
                  src="/external/vector3188-ag6u.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector047"
                />
                <img
                  src="/external/vector3188-kq72.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector048"
                />
                <img
                  src="/external/vector3188-azy.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector049"
                />
                <img
                  src="/external/vector3188-6xci.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector050"
                />
                <img
                  src="/external/vector3188-yxkk.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector051"
                />
                <img
                  src="/external/vector3188-2d2q.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector052"
                />
                <img
                  src="/external/vector3188-feq.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector053"
                />
                <img
                  src="/external/vector3188-lzeh.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector054"
                />
                <img
                  src="/external/vector3188-uzx.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector055"
                />
                <img
                  src="/external/vector3188-qdds.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector056"
                />
                <img
                  src="/external/vector3188-poms.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector057"
                />
                <img
                  src="/external/vector3188-zv2b.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector058"
                />
                <img
                  src="/external/vector3188-2bai.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector059"
                />
                <img
                  src="/external/vector3188-07ll.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector060"
                />
                <img
                  src="/external/vector3188-osm.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector061"
                />
                <img
                  src="/external/vector3188-mqmk.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector062"
                />
                <img
                  src="/external/vector3188-217a.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector063"
                />
                <img
                  src="/external/vector3188-95x1.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector064"
                />
                <img
                  src="/external/vector3188-v93o.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector065"
                />
                <img
                  src="/external/vector3188-sh0d.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector066"
                />
                <img
                  src="/external/vector3188-584.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector067"
                />
                <img
                  src="/external/vector3188-qw0v.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector068"
                />
                <img
                  src="/external/vector3188-1u7.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector069"
                />
                <img
                  src="/external/vector3188-eq0v.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector070"
                />
                <img
                  src="/external/vector3188-mbmw.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector071"
                />
                <img
                  src="/external/vector3188-ubak.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector072"
                />
                <img
                  src="/external/vector3188-62y.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector073"
                />
                <img
                  src="/external/vector3188-y7v.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector074"
                />
                <img
                  src="/external/vector3188-at86.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector075"
                />
                <img
                  src="/external/vector3188-xehm.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector076"
                />
                <img
                  src="/external/vector3188-ircc.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector077"
                />
                <img
                  src="/external/vector3188-uhwp.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector078"
                />
                <img
                  src="/external/vector3188-rg66.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector079"
                />
                <img
                  src="/external/vector3188-f2k8.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector080"
                />
                <img
                  src="/external/vector3188-fs1u.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector081"
                />
                <img
                  src="/external/vector3188-cu1k.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector082"
                />
                <img
                  src="/external/vector3188-tmr.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector083"
                />
                <img
                  src="/external/vector3188-yw4r.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector084"
                />
                <img
                  src="/external/vector3188-caio.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector085"
                />
                <img
                  src="/external/vector3188-8bo5.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector086"
                />
                <img
                  src="/external/vector3188-brsl.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector087"
                />
                <img
                  src="/external/vector3188-7yew.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector088"
                />
                <img
                  src="/external/vector3188-9nga.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector089"
                />
                <img
                  src="/external/vector3188-dkw.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector090"
                />
                <img
                  src="/external/vector3188-v1yn.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector091"
                />
                <img
                  src="/external/vector3188-jml.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector092"
                />
                <img
                  src="/external/vector3188-h3h8.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector093"
                />
                <img
                  src="/external/vector3188-bfvb.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector094"
                />
                <img
                  src="/external/vector3188-uuxc.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector095"
                />
                <img
                  src="/external/vector3188-w8z.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector096"
                />
                <img
                  src="/external/vector3188-48ml.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector097"
                />
                <img
                  src="/external/vector3188-vvto.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector098"
                />
                <img
                  src="/external/vector3188-p5r.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector099"
                />
                <img
                  src="/vector3188-v2n.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector100"
                />
                <img
                  src="/external/vector3188-gkaq.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector101"
                />
                <img
                  src="/external/vector3188-sooy.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector102"
                />
                <img
                  src="/external/vector3188-13ef.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector103"
                />
                <img
                  src="/external/vector3188-86sk.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector104"
                />
                <img
                  src="/external/vector3188-hn4g.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector105"
                />
                <img
                  src="/external/vector3188-etoe.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector106"
                />
                <img
                  src="/external/vector3188-rsvy.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector107"
                />
                <img
                  src="/external/vector3188-orq.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector108"
                />
                <img
                  src="/external/vector3188-naoq.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector109"
                />
                <img
                  src="/external/vector3188-lpd9.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector110"
                />
                <img
                  src="/external/vector3188-7hc.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector111"
                />
                <img
                  src="/external/vector3188-xr.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector112"
                />
                <img
                  src="/external/vector3188-3vgq.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector113"
                />
                <img
                  src="/external/vector3188-zcng.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector114"
                />
                <img
                  src="/external/vector3188-2vgb.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector115"
                />
                <img
                  src="/external/vector3188-w3x9.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector116"
                />
                <img
                  src="/external/vector3188-q6m.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector117"
                />
                <img
                  src="/external/vector3188-8sw.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector118"
                />
                <img
                  src="/external/vector3188-01h.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector119"
                />
                <img
                  src="/external/vector3188-0p78.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector120"
                />
                <img
                  src="/external/vector3188-ec2.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector121"
                />
                <img
                  src="/external/vector3188-216.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector122"
                />
                <img
                  src="/external/vector3188-sc4g.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector123"
                />
              </div>
              <div className="verifying-prescription-glasses">
                <img
                  src="/external/vector3188-38ps.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector124"
                />
                <img
                  src="/external/vector3188-qfh4.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector125"
                />
                <img
                  src="/external/vector3188-vrw9.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector126"
                />
                <img
                  src="/external/vector3188-xqv5.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector127"
                />
                <img
                  src="/external/vector3188-69th.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector128"
                />
                <img
                  src="/external/vector3188-ns1r.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector129"
                />
                <img
                  src="/external/vector3188-1wol.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector130"
                />
                <img
                  src="/external/vector3188-dkvv.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector131"
                />
                <img
                  src="/external/vector3188-xbhv.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector132"
                />
                <img
                  src="/external/vector3188-n9g9.svg"
                  alt="Vector3188"
                  className="verifying-prescription-vector133"
                />
              </div>
            </div>
            <div className="verifying-prescription-frame407">
              <span className="verifying-prescription-text 14Medium">
                <span>Your prescription is being analysed</span>
              </span>
              <span className="verifying-prescription-text2 14Regular">
                <span>
                  Give us a second. We will Notify you when your order is ready
                </span>
              </span>
            </div>
            <div className="verifying-prescription-frame410">
              <div className="verifying-prescription-frame408"></div>
              <div className="verifying-prescription-frame409"></div>
              <div className="verifying-prescription-frame4101"></div>
              <div className="verifying-prescription-frame411"></div>
            </div>
          </div>
          <span className="verifying-prescription-text4 14Medium">
            <span style={{cursor:'pointer'}} onClick={()=>navigate('/products')}>Browse more products</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default VerifyingPrescription

import React from "react";
// import Footer from "../components/Footer";
import WhyChooseUs from "../components/WhyChooseUs";
import MyAccount from "../components/Account/MyAccount";
import { Helmet } from 'react-helmet';

const Account = () => {
  return (
    <div>
       <Helmet>
        <title>Multimeds: Update & View Your Healthcare Account</title>
        <meta name="description" content="Discover Multimeds Healthcare: premium quality goods developed to improve your health. Our products provide efficient solutions for greater health and fitness." />
        <link rel="canonical" href="https://www.mymultimeds.com/account" />
      </Helmet>
      <MyAccount />
      <WhyChooseUs />
      {/* <Footer /> */}
    </div>
  );
};

export default Account;
